<template>
  <div class="company-settings">
    <VContainer>
      <VRow>
        <VCol class="company-settings__imgs">
          <PhotoUploader
            v-if="mainImage"
            @update-images="onImagesUpdate"
            :image="mainImage"
            class="company-settings__img company-settings__img-main"
          />

          <PhotoUploader
            v-else
            @update-images="onImagesUpdate"
            class="company-settings__img company-settings__img-main"
          />

          <div class="company-settings__subimgs">
            <template
              v-if="companyStore.currentCompany"
              v-for="(item, index) in companyStore.currentCompany?.images"
            >
              <PhotoUploader
                class="company-settings__img"
                @update-images="onImagesUpdate"
                :image="item"
                v-if="index <= 3 && index != 0"
              />
            </template>

            <PhotoUploader
              class="company-settings__img"
              @update-images="onImagesUpdate"
              v-if="companyImagesLenght <= 1"
            />
            <PhotoUploader
              class="company-settings__img"
              @update-images="onImagesUpdate"
              v-if="companyImagesLenght <= 2"
            />

            <PhotoUploader
              class="company-settings__img"
              @update-images="onImagesUpdate"
              v-if="companyImagesLenght <= 3"
            />
          </div>
        </VCol>
        <VCol class="company-settings__info">
          <div class="company-settings__captions">
            <h6>Назва бізнесу</h6>

            <div class="company-settings__name" v-if="!editName">
              <h3>{{ companyStore.currentCompany?.title }}</h3>
              <span @click="editName = !editName">Змінити</span>
            </div>

            <div class="company-settings__name" v-else>
              <input
                :value="companyStore.currentCompany?.title"
                @change="onNameChange"
                variant="underlined"
              />
            </div>
          </div>

          <div class="company-settings__feedbacks">
            {{ companyStore.currentCompany?.rating }}/5

            <div class="feedbacks">
              <div class="feedbacks__star" v-for="c in possibleRating">
                <VIcon
                  :icon="`mdi-star${ companyStore.currentCompany && companyStore.currentCompany!.rating! <= c ? '-outline' : ''}`"
                ></VIcon>
              </div>
            </div>

            <span>Відгуки</span>
          </div>

          <div class="company-settings__desc">
            <h6>Опис компанії:</h6>

            <textarea
              :value="companyStore.currentCompany?.description"
              @change="onDescriptionChange"
              placeholder="Додайте опис компанії. Заборонено вказувати конкурентів у негативному ключі. Зосередьтесь на собі та цінності саме вашої компанії"
            ></textarea>
          </div>

          <VBtn
            :loading="loading"
            class="company-settings__save"
            variant="text"
            @click="saveCompany"
          >
            Зберегти

            <template #default v-if="success">
              <VIcon icon="mdi-check-circle-outline" color="white"></VIcon>
            </template>
          </VBtn>
        </VCol>
      </VRow>
    </VContainer>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import PhotoUploader from "../photo-uploader.vue";
import { useCompanyStore } from "@/main/stores/company.store";
import { computed } from "vue";
import { updateCompany } from "@/main/services/api.service";

const companyStore = useCompanyStore();

const possibleRating = [1, 2, 3, 4, 5];
const loading = ref(false);
const success = ref(false);
const editName = ref(false);
const images = ref<
  {
    id?: number;
    url: File;
  }[]
>([]);

const onNameChange = (e: Event) => {
  companyStore.$patch({
    currentCompany: {
      title: (e.target as HTMLInputElement).value,
    },
  });
};

const onDescriptionChange = (e: Event) => {
  companyStore.$patch({
    currentCompany: {
      description: (e.target as HTMLInputElement).value,
    },
  });
};

const mainImage = computed(
  () => companyStore.currentCompany?.images[0] || null
);

const onImagesUpdate = (e: { id?: number | undefined; url: File }) => {
  images.value.push(e);
};

const companyImagesLenght = computed(() =>
  companyStore.currentCompany?.images
    ? companyStore.currentCompany?.images.length
    : 0
);

const saveCompany = async () => {
  editName.value = false;
  loading.value = true;

  if (companyStore.currentCompany) {
    const company = await updateCompany(companyStore.currentCompany.id!, {
      title: companyStore.currentCompany.title!,
      description: companyStore.currentCompany.description,
      images: images.value,
    });
    images.value = [];

    companyStore.currentCompany = null;

    companyStore.currentCompany = company;

    success.value = true;
    setTimeout(() => {
      success.value = false;
    }, 1000);
  }

  loading.value = false;
};
</script>

<style scoped lang="scss">
.company-settings {
  flex: 1;
  padding: 59px 0;

  .v-container,
  .v-row {
    height: 100%;
  }

  &__imgs {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 16px;
  }

  &__img {
    width: 100%;
    height: 100%;

    &-main {
      flex: 2.8;
    }
  }

  &__subimgs {
    display: flex;
    gap: 16px;
    flex: 1;
  }

  &__info {
    display: flex;
    flex-direction: column;
    row-gap: 53px;

    h6 {
      margin-bottom: 8px;
    }

    span {
      color: #403838;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 100% */
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  &__name {
    height: 65px;
    h3,
    input {
      width: 100%;

      color: #403838;
      font-size: 24px;
      font-weight: 600;
      line-height: 24px; /* 100% */
    }

    input {
      height: 25px;
      margin-bottom: 6px;
      outline: none;
      border-bottom: 1px solid #403838;
      padding-bottom: 1px;
    }
  }

  &__feedbacks {
    display: flex;
    gap: 16px;
    align-items: center;
    color: #403838;
    font-size: 18px;
    line-height: 15px;
  }

  &__desc {
    display: flex;
    flex-direction: column;

    h6 {
      margin-bottom: 16px;
    }

    textarea {
      display: block;
      padding: 16px;
      color: #000;
      font-size: 12px;
      line-height: 15px;
      min-height: 224px;
      height: 100%;
      width: 100%;
      border: 1px solid #403838;

      &::placeholder {
        color: #696e7c;
      }
    }
  }

  &__save {
    border-radius: 10px;
    background: #403838;
    color: #fff;
    padding: 16px;
    height: unset;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;

    i {
      font-size: 16px;
    }
  }
}

.feedbacks {
  min-width: 142px;
  height: 27px;
  display: flex;
  gap: 8px;
}
</style>
