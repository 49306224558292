<template>
  <div class="sign-up">
    <h1>booqimate</h1>
    <StartStep v-show="step == 0" @nextClick="step = 1" />
    <EmailStep
      v-show="step == 1"
      @back-click="--step"
      @next-click="onEmailNext"
    />
    <CompanyNameStep
      v-show="step == 2"
      @next-click="onNextValue"
      @back-click="--step"
    />
    <PasswordStep
      v-show="step == 3"
      @next-click="onNextValue"
      @back-click="--step"
    />
    <SelectCategoryStep
      v-show="step == 4"
      @back-click="--step"
      @next-click="onNextValue"
    />
    <AddressDescStep
      v-show="step == 5"
      @next-click="step++"
      @back-click="--step"
    />

    <CustomScheduleStep
      v-show="step == 6"
      @next-click="onNextValue"
      @back-click="--step"
    />

    <FindAddressStep
      v-show="step == 7"
      @next-click="onFindAddress"
      @back-click="--step"
    />

    <ConfirmAddressStep
      :address="signUpInfo.address"
      :geocoords="signUpInfo.geocoords"
      v-show="step == 8"
      @update:street="
        (e) => (signUpInfo.address ? (signUpInfo.address.road = e) : null)
      "
      @update:build-number="
        (e) =>
          signUpInfo.address ? (signUpInfo.address.house_number = e) : null
      "
      @update:city="
        (e) => (signUpInfo.address ? (signUpInfo.address.city = e) : null)
      "
      @update:postcode="
        (e) => (signUpInfo.address ? (signUpInfo.address.postcode = e) : null)
      "
      @next-click="onConfirmAddress"
      @back-click="--step"
    />
    <ScheduleStep
      @next-click="onScheduleUpdate"
      @back-click="--step"
      v-show="step == 9"
    />
    <FinalStep
      @back-click="--step"
      :signupInfo="signUpInfo"
      v-if="step == 10"
    ></FinalStep>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import StartStep from "../components/signup-steps/start-step.vue";
import EmailStep from "../components/signup-steps/email-step.vue";
import CompanyNameStep from "../components/signup-steps/company-name-step.vue";
import PasswordStep from "../components/signup-steps/password-step.vue";
import SelectCategoryStep from "../components/signup-steps/select-category-step.vue";
import AddressDescStep from "../components/signup-steps/address-desc-step.vue";
import CustomScheduleStep from "../components/signup-steps/custom-schedule-step.vue";
import FindAddressStep from "../components/signup-steps/find-address-step.vue";
import ConfirmAddressStep from "../components/signup-steps/confirm-address-step.vue";
import { INominatimAddress } from "../services/nominatim.service";
import { reactive } from "vue";
import ScheduleStep from "../components/signup-steps/schedule-step.vue";
import FinalStep from "../components/signup-steps/final-step.vue";
import { WorkingHoursSchedule } from "../types/working-hours-schedule.type";
import { SignUpInfoType } from "../types/signup-info.type";

const step = ref(0);
let signUpInfo = reactive<SignUpInfoType>({
  email: "",
  company_name: "",
  user_name: "",
  login: "",
  phone_number: "",
  password: "",
  password_confirmation: "",
  company_category: undefined,
  company_second_category: undefined,
  custom_schedule: false,
  address: undefined,
  geocoords: {
    lat: "",
    lon: "",
  },
  employeers_amount: 0,
  schedule: [],
});

const onEmailNext = (e: string) => {
  signUpInfo.email = e;
  step.value++;
};

const onNextValue = (
  e:
    | {
        company_name: string;
        user_name: string;
        login: string;
        phone_number: string;
      }
    | {
        password: string;
        password_confirmation: string;
      }
    | {
        company_category: number;
        company_second_category: number;
      }
    | { custom_schedule: boolean | undefined }
) => {
  signUpInfo = {
    ...signUpInfo,
    ...e,
  };

  step.value++;
};

const onFindAddress = ({
  address,
  geocoords,
}: {
  address: INominatimAddress;
  geocoords: { lat: string; lon: string };
}) => {
  signUpInfo.address = address;
  signUpInfo.geocoords = geocoords;

  step.value++;
};

const onConfirmAddress = () => {
  step.value++;
};

const onScheduleUpdate = (e: {
  customSchedule: boolean;
  schedule: WorkingHoursSchedule[];
}) => {
  if (!e.customSchedule) {
    signUpInfo.schedule = e.schedule;
  }

  step.value++;
};
</script>
<style lang="scss">
.sign-up {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  padding: 0 20px;

  &__group {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    max-width: 312px;
    width: 100%;
  }

  &__btn {
    width: 100%;
    color: #2e2a2a;
    border-radius: 15px;
    &-black {
      background: #2e2a2a;
      color: #fff;
      height: 57px;
    }
  }

  h1 {
    font-size: 50px;
    font-weight: 700;
    color: #504747;
    margin-bottom: 61px;
  }

  .v-card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__card {
    max-width: 564px;
    width: 100%;
    border-radius: 15px;
  }
}
</style>
