<template>
  <v-card class="sign-up__card">
    <v-img height="269" :src="signUpStart" cover />

    <v-card-text>
      <div class="sign-up__find-address">
        <h3>Вкажіть вашу адресу</h3>
        <div class="sign-up__find-address-wrapper">
          <VIcon icon="mdi-map-marker-plus-outline" size="24" />
          <input
            type="text"
            v-model="text"
            ref="searchInput"
            placeholder="вул. Тараса Шевченка 1"
          />
        </div>
      </div>
      <div class="sign-up__btns">
        <VBtn
          class="sign-up__btn sign-up__btn-black"
          @click="nextClick"
          :loading="loading"
          :disabled="text.length < 2"
          >Продовжити</VBtn
        >
        <VBtn class="sign-up__btn" @click="backClick" variant="text">
          Назад
        </VBtn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import signUpStart from "@/main/assets/img/find-address.png";
import {
  INominatimAddress,
  INominatimSearchParams,
  INominatimSearchResult,
  NominatimJS,
} from "@/main/services/nominatim.service";
import { ref } from "vue";
const emit = defineEmits<{
  (
    e: "nextClick",
    value: {
      address: INominatimAddress;
      geocoords: {
        lat: string;
        lon: string;
      };
    }
  ): void;
  (e: "backClick"): void;
}>();

const text = ref("");
const loading = ref(false);

const searchInput = ref<HTMLInputElement>();

const nextClick = async () => {
  if (loading.value) return;

  loading.value = true;

  searchInput.value?.setCustomValidity("");
  searchInput.value?.reportValidity();
  const params: INominatimSearchParams = {
    street: text.value,
    city: "Умань",
    addressdetails: 1,
    countrycodes: "ua",
  };
  const response: INominatimSearchResult[] = await NominatimJS.search(params);
  if (response) {
    if (response.length) {
      const { address, lat, lon } = response[0];

      if (address.house_number) {
        emit("nextClick", {
          address,
          geocoords: {
            lat,
            lon,
          },
        });

        loading.value = false;
        return;
      }
    }
    searchInput.value?.setCustomValidity(
      "Адресу на знайдено. Спробуйте деталізувати адрес"
    );
    searchInput.value?.reportValidity();
  }
  loading.value = false;
};
const backClick = () => emit("backClick");
</script>

<style lang="scss">
.sign-up {
  &__find-address {
    text-align: center;
    padding: 9px 70px;
    width: 100%;
    margin-bottom: 60px;

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
      margin-bottom: 40px;
    }

    &-wrapper {
      position: relative;
      margin-bottom: 63px;

      i {
        position: absolute;
        left: 18px;
        top: calc(50% - 14px);
      }

      input {
        padding: 15px;
        padding-left: 45px;
        color: #534e4e;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        border-radius: 15px;
        outline-color: #c4c3c3;
        background: #ececec;
        width: 100%;
      }
    }
  }
}
</style>
