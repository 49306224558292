<template>
  <header class="header">
    <div class="header__logo">
      <h1>Booqimate</h1>
    </div>

    <div class="header__btns">
      <RouterLink :to="Routes.Admin" v-slot="{ navigate }">
        <VBtn
          icon="mdi-home"
          :ripple="false"
          variant="text"
          @click="navigate"
        />
      </RouterLink>

      <RouterLink :to="Routes.AdminEmployees" v-slot="{ navigate }">
        <VBtn
          :ripple="false"
          icon="mdi-account-group-outline"
          variant="text"
          @click="navigate"
        />
      </RouterLink>

      <RouterLink :to="Routes.AdminSchedule" v-slot="{ navigate }">
        <VBtn
          :ripple="false"
          icon="mdi-calendar-clock-outline"
          variant="text"
          @click="navigate"
        />
      </RouterLink>

      <RouterLink :to="Routes.AdminSettings" v-slot="{ navigate }">
        <VBtn
          icon="mdi-cog-outline"
          :ripple="false"
          variant="text"
          @click="navigate"
        />
      </RouterLink>

      <VMenu>
        <template #activator="{ props }">
          <VBtn
            icon="mdi-home-switch"
            :ripple="false"
            v-bind="props"
            variant="text"
          ></VBtn>
        </template>

        <VList
          @click:select="onCompanyChange"
          :selected="[companyStore.currentCompany]"
        >
          <VListItem
            v-for="item in companyStore.userCompanies"
            :key="item.id"
            :value="item"
          >
            <VListItemTitle>
              {{ item.title }}
            </VListItemTitle>
          </VListItem>
        </VList>
      </VMenu>

      <VBtn
        icon="mdi-exit-to-app"
        variant="text"
        class="header__btns-end"
        @click="logoutApp"
      />
    </div>
  </header>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { getUserCompanies, logout } from "../services/api.service";
import { useCompanyStore } from "../stores/company.store";
import { Routes } from "../types/enums/routes.enum";
import { Company } from "../types/company.type";
import { onMounted } from "vue";

const companyStore = useCompanyStore();
const router = useRouter();

onMounted(async () => {
  const companies = await getUserCompanies();

  if (companies.length) {
    companyStore.userCompanies = companies;
    const currentCompany = companies.find(
      (company) => company.id == companyStore.currentCompany?.id
    );
    if (currentCompany) {
      companyStore.currentCompany = currentCompany;
    }
  } else {
    await logout();
  }
});

const logoutApp = async () => {
  await logout();
  companyStore.$reset();
  router.push(Routes.Main);
};

const onCompanyChange = ({
  id,
}: {
  id: unknown;
  value: boolean;
  path: unknown[];
}) => (companyStore.currentCompany = id as Company);
</script>

<style lang="scss" scoped>
.header {
  border-radius: 0px 0px 20px 20px;
  background: #fff;
  width: 100%;
  // height: 70px;
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &__logo {
    max-width: 300px;

    h1 {
      color: #504747;

      text-align: center;
      font-family: Montserrat;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
    }
  }

  &__btns {
    display: flex;
    column-gap: 36px;

    :deep(.v-icon) {
      font-size: 30px;
      color: black;
    }

    &-end {
      margin-left: 30px;
    }
  }
}
</style>
