<template>
  <v-card class="sign-up__card">
    <v-card-text>
      <div class="sign-up__customschedule-content">
        <VIcon icon="mdi-timetable" size="large" class="sign-up__icon" />

        <h3>У якому режимі ви працюєте?</h3>
        <div class="sign-up__group">
          <div class="sign-up__customschedule-wrapper">
            <input
              id="customschedule-1"
              required
              name="customschedule"
              ref="customScheduleInput"
              type="radio"
              v-model="customSchedule"
              :value="false"
            />
            <label for="customschedule-1">Cтабільний графік</label>
          </div>

          <div class="sign-up__customschedule-wrapper">
            <input
              id="customschedule-2"
              required
              name="customschedule"
              ref="customScheduleInput"
              type="radio"
              v-model="customSchedule"
              :value="true"
            />
            <label for="customschedule-2">Динамічний графік</label>
          </div>
        </div>
      </div>
      <div class="sign-up__btns">
        <VBtn @click="nextClick" class="sign-up__btn sign-up__btn-black">
          Продовжити
        </VBtn>
        <VBtn class="sign-up__btn" @click="backClick" variant="text">
          Назад
        </VBtn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const emit = defineEmits<{
  (
    e: "nextClick",
    value: {
      custom_schedule: boolean | undefined;
    }
  ): void;
  (e: "backClick"): void;
}>();

const customSchedule = ref<boolean | undefined>(undefined);

const customScheduleInput = ref<HTMLInputElement>();

const nextClick = () => {
  if (!customScheduleInput.value?.checkValidity()) {
    customScheduleInput.value?.setCustomValidity("Виберіть один із варіантів");
    customScheduleInput.value?.reportValidity();
    return;
  } else {
    customScheduleInput.value.setCustomValidity("");
    customScheduleInput.value?.reportValidity();
  }

  emit("nextClick", {
    custom_schedule: customSchedule.value,
  });
};
const backClick = () => emit("backClick");
</script>

<style lang="scss" scoped>
.sign-up {
  &__card {
    width: 100%;
    height: 642px;
  }

  &__customschedule-wrapper {
    position: relative;

    input:checked + label {
      border: 1px solid #c4c3c3;
    }

    label {
      margin-top: -13px;
      border: 1px solid transparent;
      width: 100%;
      padding: 15px;
      border-radius: 15px;
      background: #ececec;
      color: #807575;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  &__icon {
    color: #2e2a2a;
    font-size: 60px;
    margin-bottom: 75px;
  }

  .v-card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__customschedule-content {
    width: 100%;
    text-align: center;
    padding: 9px 90px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
      max-width: 300px;
      margin-bottom: 24px;
    }

    input {
      display: block;
      width: 100%;
      // height: 0;
      opacity: 0;
    }
  }
}
</style>
