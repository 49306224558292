<template>
  <v-card class="sign-up__card">
    <v-card-text>
      <div class="sign-up__company-name-content">
        <VIcon icon="mdi-account-circle-outline" size="large" />

        <h3>Про Вас</h3>
        <div class="sign-up__group">
          <input
            required
            ref="companyNameInput"
            type="text"
            v-model="companyName"
            placeholder="Назва Компанії"
          />

          <input
            required
            ref="userNameInput"
            type="text"
            v-model="userName"
            placeholder="Ваше ім’я та прізвище"
          />

          <input
            required
            ref="loginInput"
            type="text"
            v-model="login"
            placeholder="Ваш майбутній логін"
          />

          <div class="sign-up__phone">
            <input
              required
              ref="phoneNumberInput"
              type="text"
              pattern="\d"
              maxlength="9"
              v-model="phoneNumber"
              placeholder="Ваш номер телефону"
            />
          </div>
        </div>
      </div>
      <div class="sign-up__btns">
        <VBtn @click="nextClick" class="sign-up__btn sign-up__btn-black">
          Продовжити
        </VBtn>
        <VBtn class="sign-up__btn" @click="backClick" variant="text">
          Назад
        </VBtn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const emit = defineEmits<{
  (
    e: "nextClick",
    value: {
      company_name: string;
      user_name: string;
      login: string;
      phone_number: string;
    }
  ): void;
  (e: "backClick"): void;
}>();

const companyName = ref("");
const userName = ref("");
const phoneNumber = ref("");
const login = ref("");
const companyNameInput = ref<HTMLInputElement>();
const loginInput = ref<HTMLInputElement>();
const userNameInput = ref<HTMLInputElement>();
const phoneNumberInput = ref<HTMLInputElement>();

const nextClick = () => {
  if (!companyNameInput.value?.checkValidity()) {
    companyNameInput.value?.reportValidity();
    companyNameInput.value?.focus();
    return;
  }

  if (!userNameInput.value?.checkValidity()) {
    userNameInput.value?.reportValidity();
    userNameInput.value?.focus();
    return;
  }

  if (!loginInput.value?.checkValidity()) {
    loginInput.value?.reportValidity();
    loginInput.value?.focus();
    return;
  }

  if (phoneNumberInput.value) {
    const phoneNumber = phoneNumberInput.value!.value;
    const phoneNumberPattern = /^\d{9}$/;

    if (!phoneNumberPattern.test(phoneNumber)) {
      phoneNumberInput.value.setCustomValidity(
        "Введіть коректний номер з 9 цифр"
      );

      phoneNumberInput.value?.reportValidity();
      phoneNumberInput.value.focus();
      return;
    } else {
      phoneNumberInput.value.setCustomValidity("");
    }
  }

  emit("nextClick", {
    company_name: companyName.value,
    user_name: userName.value,
    phone_number: phoneNumber.value,
    login: login.value,
  });
};
const backClick = () => emit("backClick");
</script>

<style lang="scss" scoped>
.sign-up {
  &__card {
    width: 100%;
    height: 642px;
  }

  .v-card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__phone {
    padding-left: 5rem;
    position: relative;

    &::after {
      content: "+380";
      top: 0;
      left: 0;
      width: 4.375rem;
      height: 100%;
      position: absolute;
      border-radius: 15px;
      background-color: #ececec;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
    }

    input {
      display: block;
      position: relative;
    }
  }

  &__company-name-content {
    width: 100%;
    text-align: center;
    padding: 9px 90px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
      max-width: 300px;
      margin-bottom: 24px;
    }
    .v-icon {
      color: #2e2a2a;
      font-size: 60px;
      margin-bottom: 75px;
    }

    input {
      display: block;
      width: 100%;
      border-radius: 15px;
      background-color: #ececec;
      padding: 15px;
      text-align: center;
      outline-color: #c4c3c3;
      color: #807575;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
    }
    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
    }
  }
}
</style>
