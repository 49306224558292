import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { LocalStorageKeys, localForageStorage } from "./storage.helper";
import { AuthTokens } from "../types/auth-tokens.type";
import { shouldRefreshToken } from "./session.helper";
import { logout, refreshToken } from "../services/api.service";
import { useRouter } from "vue-router";
import { Routes } from "../types/enums/routes.enum";
import { router } from "../plugins/router.plugin";

export const onRequestInterceptor = async (
  config: InternalAxiosRequestConfig<any>
) => {
  let tokens: AuthTokens | null = await localForageStorage.getItem(
    LocalStorageKeys.Tokens
  );

  if (!tokens) return config;

  if (shouldRefreshToken(tokens.refreshedAt, tokens.expires_in * 1000)) {
    try {
      tokens = await refreshToken(tokens.refresh_token);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  config.headers.Authorization = `Bearer ${tokens.access_token}`;
  return config;
};

export const getAxiosInstance = (baseURL: string, withAuth = true) => {
  const axiosInstance = axios.create({
    baseURL,
  });

  if (withAuth) {
    axiosInstance.interceptors.request.use(onRequestInterceptor);
  }

  axiosInstance.interceptors.response.use(null, async (error) => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status == 401) {
        try {
          let tokens: AuthTokens | null = await localForageStorage.getItem(
            LocalStorageKeys.Tokens
          );

          if (tokens) {
            const axiosRetry = axios.create({
              baseURL,
            });
            const { data } = await axiosRetry.post<AuthTokens>(
              "/refresh",
              null,
              {
                headers: {
                  Authorization: `Bearer ${tokens.refresh_token}`,
                },
              }
            );

            tokens = await localForageStorage.setItem(LocalStorageKeys.Tokens, {
              ...data,
              refreshedAt: new Date().getTime(),
            });

            if (error.config) {
              const response = axiosApiInstance.call(
                error.config.method,
                error.config.url!
              );

              return Promise.resolve(response);
            }

            throw error;
          }
        } catch (error) {
          await logout();
          router.push(Routes.Main);
        }
      }
    }
    return Promise.reject(error);
  });

  return axiosInstance;
};

export const axiosApiInstanceNoAuth = getAxiosInstance(
  (import.meta as any).env.VITE_RUBY_API_URL,
  false
);

export const axiosApiInstance = getAxiosInstance(
  (import.meta as any).env.VITE_RUBY_API_URL
);
