import axios, { AxiosResponse } from "axios";

export interface INominatimCommonParams {
  format?: "html" | "json" | "xml" | "jsonv2";
  json_callback?: string;
  accept_language?: string;
  "accept-language"?: string;
  addressdetails?: 0 | 1;
  extratags?: 0 | 1;
  namedetails?: 0 | 1;
  email?: string;
  debug?: 0 | 1;
}

// from: https://wiki.openstreetmap.org/wiki/Nominatim#Parameters_2
export interface INominatimSearchParams extends INominatimCommonParams {
  q?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  viewbox?: string;
  postalcode?: string;
  countryCodesArray?: string[];
  countrycodes?: string;
  bounded?: 0 | 1;
  polygon?: 0 | 1;
  email?: string;
  exclude_place_ids?: string;
  limit?: number;
  dedupe?: 0 | 1;
}

export interface INominatimAddress {
  house_number?: string;
  road?: string;
  neighbourhood?: string;
  suburb?: string;
  postcode?: string;
  city: string;
  town: string;
  city_district?: string;
  county?: string;
  state: string;
  country: string;
  country_code: string;
  continent?: string;
  public_building?: string;
  attraction?: string;
  pedestrian?: string;
  peak?: string;
  bakery?: string;
  electronics?: string;
  construction?: string;
}

export interface INominatimSearchResult {
  place_id: string;
  osm_id: string;
  osm_type: PlaceTypeLabel;
  boundingbox?: string[4];
  lat: string;
  lon: string;
  display_name: string;
  class: string;
  type: string;
  importance: number;
  icon: string;
  address: INominatimAddress;
  licence: string;
  svg?: string;
}

// For backward compatibility
export type INominatimResult = INominatimSearchResult;

const PLACES_TYPES = {
  node: "N" as "N",
  way: "W" as "W",
  relation: "R" as "R",
};

type Places = typeof PLACES_TYPES;
type PlaceTypeLabel = keyof Places;
type PlaceTypeId = Places[PlaceTypeLabel];

export interface IOsmId {
  type: PlaceTypeLabel;
  id: number;
}

export interface ILookupParams extends INominatimCommonParams {}

export class NominatimJS {
  private static NOMINATIM_ENDPOINT = "https://nominatim.openstreetmap.org/";

  private static normalizeParams<T extends INominatimCommonParams>(
    params: T
  ): T {
    return {
      ...params,
      format: params.format || "json",
      "accept-language":
        params["accept-language"] || params.accept_language || "uk",
    };
  }

  private static stringifyOsmId(osmId: IOsmId): string {
    return `${PLACES_TYPES[osmId.type]}${osmId.id}`;
  }

  public static async search(
    rawParams: INominatimSearchParams
  ): Promise<INominatimSearchResult[]> {
    const params = NominatimJS.normalizeParams(rawParams);

    return await axios
      .get(`${NominatimJS.NOMINATIM_ENDPOINT}search`, {
        params: {
          ...params,
          countrycodes:
            params.countrycodes ||
            (params.countryCodesArray
              ? params.countryCodesArray.join(",")
              : undefined),
        },
      })
      .then((res: AxiosResponse) => res.data || []);
  }

  public static async lookup(
    osmIds: IOsmId[],
    rawParams: ILookupParams
  ): Promise<INominatimSearchResult[]> {
    const params = NominatimJS.normalizeParams(rawParams);

    return await axios
      .get(`${NominatimJS.NOMINATIM_ENDPOINT}lookup`, {
        params: {
          ...params,
          osm_ids: osmIds.map(NominatimJS.stringifyOsmId).join(","),
        },
      })
      .then((res: AxiosResponse) => res.data || []);
  }
}
