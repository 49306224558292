<template>
  <div class="services-settings">
    <div class="table-view__table">
      <div class="table-view__body">
        <div class="table-view__header">
          <div class="table-view__name service">Послуги</div>
          <div>Ціна</div>
          <div>Тривалість</div>
          <div>Деталі</div>
        </div>
        <template v-for="service in companyStore.services">
          <div class="table-view__name">
            <h3>{{ getService(service).name }}</h3>
            <p>{{ service.description || getService(service).description }}</p>
          </div>
          <div>{{ service.price }}</div>
          <div>{{ service.duration }}</div>
          <div class="table-view__actions">
            <VBtn icon @click="openDialog(service)">
              <v-icon small>mdi-pencil</v-icon>
            </VBtn>
            <VBtn color="red" icon @click="openDeleteDialog(service.id!)">
              <v-icon small>mdi-delete</v-icon>
            </VBtn>
          </div>
        </template>
        <DeleteDialog
          v-model="deleteDialog"
          @delete="deleteService"
          @cancel="serviceId = undefined"
        />
      </div>
    </div>
    <VBtn class="table-view__add" @click="dialog = true">Додати послугу +</VBtn>
    <VDialog width="500" v-model="dialog" eager>
      <!-- @update:model-value="(e) => onChangeEmployeeDialog(e)" -->
      <VCard>
        <VCardTitle>
          <span class="text-h5">{{
            currentService.id ? "Змінити послугу" : "Додати послугу +"
          }}</span>
        </VCardTitle>
        <VCardText>
          <v-container>
            <VRow>
              <VCol cols="12">
                <VSelect
                  :items="globalStore.services"
                  item-title="name"
                  item-value="id"
                  label="Послуга"
                  required
                  v-model="(currentService.service_id as any)"
                >
                  <template #no-data>
                    <p>Послуг не знайдено</p>
                  </template>
                </VSelect>
                <v-text-field
                  label="Ваш опис"
                  variant="underlined"
                  required
                  v-model="currentService.description"
                ></v-text-field>
                <v-text-field
                  label="Ціна"
                  type="number"
                  variant="underlined"
                  required
                  v-model="currentService.price"
                ></v-text-field>
                <VSelect
                  :items="[15, 30, 45, 60, 90, 120, 160]"
                  label="Тривалість, хв"
                  required
                  v-model="(currentService.duration as any)"
                >
                </VSelect>
              </VCol>
            </VRow>
          </v-container>
        </VCardText>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="closeDialog(true)"
          >
            Відміна
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="closeDialog(false)"
          >
            Зберегти
          </v-btn>
        </v-card-actions>
      </VCard>
    </VDialog>
  </div>
</template>

<script setup lang="ts">
import {
  createCompanyService,
  deleteCompanyService,
  getCompanyServices,
  updateCompanyService,
} from "@/main/services/api.service";
import { useCompanyStore } from "@/main/stores/company.store";
import { useGlobalStore } from "@/main/stores/global.store";
import { CompanyService } from "@/main/types/service.type";
import DeleteDialog from "../delete-popup.vue";
import { onMounted } from "vue";
import { ref } from "vue";

const companyStore = useCompanyStore();
const globalStore = useGlobalStore();

const dialog = ref(false);
const deleteDialog = ref(false);

const currentService = ref<CompanyService>({
  service_id: undefined,
  price: 0,
  duration: 15,
});

const serviceId = ref<number>();

onMounted(async () => {
  try {
    if (companyStore.currentCompany?.id) {
      const companyServices = await getCompanyServices(
        companyStore.currentCompany?.id
      );

      companyStore.services = companyServices;
    }
  } catch (error) {
    console.log(error);
  }
});

const getService = (service: CompanyService) =>
  globalStore.services.find((gs) => gs.id == service.service_id) ||
  globalStore.services[0];

const closeDialog = async (clear: boolean) => {
  dialog.value = false;

  if (companyStore.currentCompany) {
    if (currentService.value.id) {
      try {
        const service = await updateCompanyService(
          companyStore.currentCompany!.id!,
          {
            service_id: currentService.value.service_id!,
            description: currentService.value.description,
            price: currentService.value.price,
            duration: currentService.value.duration,
            id: currentService.value.id,
          }
        );

        const index = companyStore.services.findIndex(
          (ser) => ser.id == service.id
        );

        if (index != -1) {
          companyStore.services[index] = {
            ...service,
          };
          clear = true;
        }
      } catch (error) {
        console.log(error);
      }
    } else if (currentService.value.service_id) {
      try {
        const service = await createCompanyService(
          companyStore.currentCompany!.id!,
          {
            service_id: currentService.value.service_id,
            description: currentService.value.description,
            price: currentService.value.price,
            duration: currentService.value.duration,
          }
        );

        companyStore.services.push(service);
        clear = true;
      } catch (error) {
        console.log(error);
      }
    }
  }
  if (clear) {
    currentService.value = {
      service_id: undefined,
      price: 0,
      duration: 15,
    };
    return;
  }
};

const deleteService = async () => {
  if (companyStore.currentCompany && serviceId.value) {
    try {
      await deleteCompanyService(
        companyStore.currentCompany.id!,
        serviceId.value
      );
      companyStore.services = companyStore.services.filter(
        (service) => service.id != serviceId.value
      );
    } catch (error) {
      console.log(error);
    }
  }
  deleteDialog.value = false;
  serviceId.value = undefined;
};

const openDialog = (service: CompanyService) => {
  currentService.value = { ...service };
  dialog.value = true;
};

const openDeleteDialog = (id: number) => {
  deleteDialog.value = true;
  serviceId.value = id;
};
</script>

<style lang="scss" scoped></style>
