<template>
  <v-card class="sign-up__card">
    <v-card-text>
      <div class="sign-up__email-content">
        <VIcon icon="mdi-at" size="large" />

        <h3>Введіть вашу електронну адресу</h3>
        <input
          required
          ref="input"
          type="email"
          v-model.trim="email"
          placeholder="user@example.com"
        />
      </div>
      <div class="sign-up__btns">
        <VBtn @click="nextClick" class="sign-up__btn sign-up__btn-black">
          Продовжити
        </VBtn>
        <VBtn class="sign-up__btn" @click="backClick" variant="text">
          Назад
        </VBtn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const emit = defineEmits<{
  (e: "nextClick", value: string): void;
  (e: "backClick"): void;
}>();

const email = ref("");
const input = ref<HTMLInputElement>();

const nextClick = () => {
  if (!input.value?.checkValidity()) {
    input.value?.reportValidity();
    return;
  }
  emit("nextClick", email.value);
};
const backClick = () => emit("backClick");
</script>

<style lang="scss" scoped>
.sign-up {
  &__card {
    width: 100%;
    height: 642px;
  }

  .v-card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__email-content {
    width: 100%;
    text-align: center;
    padding: 9px 90px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
      max-width: 300px;
      margin-bottom: 24px;
    }
    .v-icon {
      color: #2e2a2a;
      font-size: 60px;
      margin-bottom: 75px;
    }

    input {
      display: block;
      width: 100%;
      border-radius: 15px;
      background-color: #ececec;
      padding: 15px;
      text-align: center;
      outline-color: #c4c3c3;
    }
    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
    }
  }
}
</style>
