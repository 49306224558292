<template>
  <div class="unconfirmed-list">
    <h2>Непідтверджені візити</h2>
    <VContainer>
      <VRow justify="center">
        <VProgressCircular indeterminate color="primary" v-if="loading" />
      </VRow>
    </VContainer>

    <v-snackbar v-model="snackbar" timeout="2500">
      {{ snackbarText }}

      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="snackbar = false">
          Закрити
        </v-btn>
      </template>
    </v-snackbar>

    <div class="unconfirmed-list__list" v-if="reservations.length">
      <UnconfirmedItem
        v-for="reservation in reservations"
        :key="reservation.id"
        :title="getServiceName(reservation)"
        :description="getReservationDescription(reservation)"
        :employees="reservation.employees"
        :reservation="reservation"
        @update-reservation="updateReservation"
        @confirm-reservation="confirmReservation"
      >
      </UnconfirmedItem>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import UnconfirmedItem from "./unconfirmed-item.vue";
import { ref } from "vue";
import { Reservation } from "../types/reservation.type";
import { getCompanyWaitList } from "../services/api.service";
import moment from "moment";
import { useCompanyStore } from "../stores/company.store";
import { watch } from "vue";
import { storeToRefs } from "pinia";

const loading = ref(false);

const reservations = ref<Reservation[]>([]);

const snackbar = ref(false);
const snackbarText = ref("");

const companyStore = useCompanyStore();

const { currentCompany } = storeToRefs(companyStore);

const getServiceName = (reservation: Reservation) =>
  reservation.services.length ? reservation.services[0].name : "Без послуги";

const getReservationDescription = (reservation: Reservation) => {
  try {
    const date = moment(reservation.start_date);

    let description = `${date.utc().format("D MMMM YYр, HH:mm")}, ${
      reservation.services_sum
    } грн`;
    return description;
  } catch (error) {
    console.log(error);
  }
  return "";
};

watch(currentCompany, (newValue, oldValue) => {
  if (newValue?.id != oldValue?.id) {
    loadUncofirmedReservations();
  }
});

onMounted(() => {
  loadUncofirmedReservations();
});

const loadUncofirmedReservations = async () => {
  loading.value = true;
  reservations.value = [];
  try {
    const res = await getCompanyWaitList();
    reservations.value.push(...res);
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
};

const updateReservation = (updatedReservation: Reservation) => {
  const reservationIndex = reservations.value.findIndex(
    (reserv) => reserv.id == updatedReservation.id
  );
  if (reservationIndex != -1)
    reservations.value[reservationIndex] = updatedReservation;

  snackbarText.value = "Візит оновлено.";
  snackbar.value = true;
};

const confirmReservation = async (reservation: Reservation) => {
  let arrayCopy = [...reservations.value];
  const reservationIndex = arrayCopy.findIndex(
    (reserv) => reserv.id == reservation.id
  );
  if (reservationIndex != -1) arrayCopy.splice(reservationIndex, 1);

  reservations.value = arrayCopy;

  snackbarText.value = "Візит підтверджено.";
  snackbar.value = true;
};
</script>

<style lang="scss" scoped>
.unconfirmed-list {
  padding: 30px 20px;

  &__list {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  h2 {
    color: #2e2a2a;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
  }
}
</style>
