import { Employee } from "./employee.type";

export enum ReservationStatus {
  Draft = "draft",
  Created = "created",
  Confirmed = "confirmed",
  Rejected = "rejected",
  Canceled = "canceled",
  Completed = "completed",
  Archived = "archived",
}

export type Reservation = {
  id: number;
  start_date: string;
  end_date: string;
  status: ReservationStatus;
  comment: string;
  services_sum?: number;
  services: {
    id: number;
    name: string;
  }[];
  users: { id: string; name: string; phone_number: string }[];
  employees: Employee[];
  url: string;
};

export interface UpdateReservationDTO {
  id: number;
  employee_ids?: number[];
  service_ids?: number[];
  start_date?: string;
  end_date?: string;
  status?: ReservationStatus;
}

export interface CreateReservationDTO {
  employee_ids?: number[];
  service_ids?: number[];
  start_date?: string;
  end_date?: string;
  status?: ReservationStatus;
}
