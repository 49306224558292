<template>
  <v-card class="sign-up__card">
    <v-card-text>
      <div class="sign-up__schedule-content">
        <VIcon
          icon="mdi-lock-open-outline"
          size="large"
          class="sign-up__icon"
        />

        <h3>Ваші години роботи</h3>
        <div class="sign-up__group">
          <ScheduleList
            @update:weekobject="onWeekObjectUpdate"
            @update:custom="onCustomScheduleUpdate"
          />
        </div>
      </div>
      <div class="sign-up__btns">
        <VBtn @click="nextClick" class="sign-up__btn sign-up__btn-black">
          Продовжити
        </VBtn>
        <VBtn class="sign-up__btn" @click="backClick" variant="text">
          Назад
        </VBtn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import ScheduleList from "../schedule-list.vue";
import { WorkingHoursSchedule } from "@/main/types/working-hours-schedule.type";

const schedule = ref<WorkingHoursSchedule[]>([]);
const customSchedule = ref(false);

const addZero = (num: number): string => {
  if (num <= 9 && num >= 0) {
    return `0${num}`;
  }

  return `${num}`;
};

const onWeekObjectUpdate = (weekObject: WorkingHoursSchedule[]) => {
  schedule.value = weekObject;
};

const onCustomScheduleUpdate = (custom: boolean) => {
  customSchedule.value = custom;
};

const emit = defineEmits<{
  (
    e: "nextClick",
    value: {
      customSchedule: boolean;
      schedule: WorkingHoursSchedule[];
    }
  ): void;
  (e: "backClick"): void;
}>();

const nextClick = () => {
  emit("nextClick", {
    customSchedule: customSchedule.value,
    schedule: schedule.value,
  });
};
const backClick = () => emit("backClick");
</script>

<style lang="scss" scoped>
.sign-up {
  &__card {
    width: 100%;
    height: 820px;
  }

  &__icon {
    color: #2e2a2a;
    font-size: 60px;
    margin-bottom: 75px;
  }

  .v-card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__schedule-content {
    width: 100%;
    text-align: center;
    padding: 9px 50px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .timepicker {
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
      max-width: 300px;
      margin-bottom: 24px;
    }
  }
}
</style>
