import { defineStore } from "pinia";
import { Company, CurrentCompany } from "../types/company.type";
import { Employee } from "../types/employee.type";
import { CompanyService } from "../types/service.type";

interface CompanyState {
  currentCompany: CurrentCompany | null;
  userCompanies: Company[];
  employees: Employee[];
  services: CompanyService[];
}

export const useCompanyStore = defineStore("company", {
  state: (): CompanyState => ({
    userCompanies: [],
    currentCompany: null,
    employees: [],
    services: [],
  }),
  persist: {
    paths: ["currentCompany", "userCompanies"],
  },
});
