<template>
  <v-card class="sign-up__card">
    <v-img height="269" :src="adressDesc" cover />

    <v-card-text>
      <div class="sign-up__start-content">
        <h3>Де та як клієнти можуть знайти Вас?</h3>

        <p>
          Розкажіть трохи більше про те де, та як працюєте. Завдяки цьому
          клієнти зможуть легко знайти Ваш заклад та на основі вказаних Вами
          годин роботи буде вестись автоматичний розрахунок вільних годин.
        </p>
      </div>
      <div class="sign-up__btns">
        <VBtn class="sign-up__btn sign-up__btn-black" @click="nextClick"
          >Продовжити</VBtn
        >
        <VBtn class="sign-up__btn" @click="backClick" variant="text"
          >Назад</VBtn
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import adressDesc from "@/main/assets/img/address-desc.png";
const emit = defineEmits<{
  (e: "nextClick"): void;
  (e: "backClick"): void;
}>();

const nextClick = () => emit("nextClick");
const backClick = () => emit("backClick");
</script>

<style lang="scss">
.sign-up {
  &__start-content {
    text-align: center;
    padding: 9px 70px;
    width: 100%;
    margin-bottom: 60px;

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
    }
  }
}
</style>
