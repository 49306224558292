<template>
  <v-card class="sign-up__card">
    <v-img height="269" :src="signUpStart" cover />

    <v-card-text>
      <div class="sign-up__start-content">
        <h3>Підтримка в розвитку бізнесу</h3>

        <p>
          З <b>Booqimate</b> ви отримаєте підтримку в керуванні бізнесом і
          корисні маркетингові інструменти, а клієнти - цілодобово бронювати
          візити. Зосереджуйтесь на важливому, а про решту подбаємо ми!
        </p>
      </div>
      <div class="sign-up__btns">
        <VBtn class="sign-up__btn sign-up__btn-black" @click="nextClick"
          >Розпочати</VBtn
        >
        <RouterLink :to="Routes.Main" v-slot="{ navigate }">
          <VBtn class="sign-up__btn" @click="navigate" variant="text">
            Назад
          </VBtn>
        </RouterLink>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import signUpStart from "@/main/assets/img/sign-up-start.png";
import { Routes } from "@/main/types/enums/routes.enum";
const emit = defineEmits<{
  (e: "nextClick"): void;
}>();

const nextClick = () => emit("nextClick");
</script>

<style lang="scss">
.sign-up {
  &__start-content {
    text-align: center;
    padding: 9px 70px;
    width: 100%;
    margin-bottom: 60px;

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
    }
  }
}
</style>
