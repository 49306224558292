<template>
  <div
    class="calendar-week__event-info-wrapper"
    :style="{
      color: eventColor,
      width: '100%',
      height: '100%',
      backgroundColor: eventBackgroundColor,
    }"
  >
    <div class="calendar-week__event-row is-title">
      {{ event.title }}
    </div>

    <div class="calendar-week__event-row is-time">
      <VIcon :icon="icons.clock" class="calendar-week__event-icon" />
      <span>{{ getEventTime(event) }}</span>
    </div>

    <div v-if="event.with" class="calendar-week__event-row is-with">
      <VIcon :icon="icons.user" class="calendar-week__event-icon" />
      <span>{{ event.with }}</span>
    </div>

    <div
      v-if="eventIsLongerThan30Minutes"
      class="calendar-week__event-blend-out"
      :style="{
        backgroundImage:
          'linear-gradient(to bottom, transparent, ' +
          eventBackgroundColor +
          ')',
      }"
    />
  </div>
</template>
<script lang="ts" setup>
import moment from "moment";
import { computed, onMounted, ref } from "vue";

const props = defineProps<{
  event: any;
  config: any;
}>();

const icons = {
  clock: "mdi-clock-time-four-outline",
  location: "mdi-map-marker",
  user: "mdi-account",
  description: "mdi-image-text",
};

const eventBackgroundColor = ref("");
const eventColor = ref("");

const setEventColors = () => {
  if (
    props.event?.colorScheme &&
    props.config.style?.colorSchemes &&
    props.config.style.colorSchemes[props.event.colorScheme]
  ) {
    eventColor.value =
      props.config.style.colorSchemes[props.event.colorScheme].color;
    return (eventBackgroundColor.value =
      props.config.style.colorSchemes[props.event.colorScheme].backgroundColor);
  }
};

const eventIsLongerThan30Minutes = computed(() => {
  const eventDuration = moment.duration(
    moment(props.event.end).diff(moment(props.event.start))
  );

  return eventDuration.asMinutes() > 30;
});

const getEventTime = (event: any) => {
  if (!event.time.start || !event.time.end) return "";
  const start = event.time.start.split(" ")[1];
  const end = event.time.end.split(" ")[1];

  return `${start} - ${end}`;
};

onMounted(() => {
  setEventColors();
});
</script>

<style lang="scss" scoped>
.calendar-week__event {
  position: absolute;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: content-box;
  user-select: none;
  overflow: hidden;

  &.is-editable {
    cursor: grab;
  }

  &.has-disabled-dnd {
    cursor: initial;
  }

  .calendar-week__event-row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.25em;

    p {
      margin: 0;
      padding: 0;
    }
  }

  .calendar-week__event-info-wrapper {
    position: relative;
    padding: 5px;
    font-size: var(--qalendar-font-xs);
    height: 100%;
    box-sizing: border-box;
    user-select: none;
  }

  .calendar-week__event-blend-out {
    position: absolute;
    bottom: 0;
    height: 20px;
    width: 100%;
    transform: translateX(calc(var(--qalendar-spacing-half) * -1));
  }

  .calendar-week__event-icon {
    margin: 2px 4px 0 0;
    font-size: var(--qalendar-font-xs);
  }

  .calendar-week__event-resize {
    position: absolute;
    width: 100%;
    cursor: ns-resize;
    height: 5px;
  }

  .calendar-week__event-resize-up {
    top: 0;
  }

  .calendar-week__event-resize-down {
    bottom: 0;
  }
}
</style>
