<template>
  <div class="table-view">
    <div class="table-view__table">
      <div class="table-view__body">
        <div class="table-view__header">
          <div class="table-view__name employee">Працівник</div>
          <div>Рейтинг</div>
          <div></div>
        </div>
        <template v-for="employee in companyStore.employees">
          <div class="table-view__name">
            <VAvatar color="grey">
              <VImg
                :src="employee.avatar"
                :alt="employee.name"
                v-if="
                  employee.avatar &&
                  typeof employee.avatar == 'string' &&
                  employee.avatar.length
                "
              />
              <VIcon icon="mdi-account" v-else></VIcon>
            </VAvatar>
            <h3>{{ employee.name }}</h3>
          </div>
          <div>★★★★☆</div>
          <div class="table-view__actions">
            <VBtn icon @click="openDialog(true, employee)">
              <v-icon small>mdi-pencil</v-icon>
            </VBtn>
            <VBtn color="red" icon @click="openDeleteDialog(employee.id!)">
              <v-icon small>mdi-delete</v-icon>
            </VBtn>
          </div>
        </template>
        <EmployeeDialog
          :opened="dialog"
          :employee="currentEmployee"
          @closeWithoutSave="closeDialog"
          @saveEmployee="updateEmployee"
          :editable="false"
        />
        <DeleteDialog
          v-model="deleteDialog"
          @delete="deleteEmployee"
          @cancel="employeeId = undefined"
        />
      </div>
    </div>
    <VBtn class="table-view__add" @click="() => openDialog(false, newEmployee)"
      >Додати працівника +</VBtn
    >

    <div v-if="newEmployee">
      <EmployeeDialog
        :opened="createDialog"
        :employee="newEmployee"
        editable
        @closeWithoutSave="closeDialog"
        @saveEmployee="saveEmployee"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  createCompanyEmployee,
  deleteCompanyEmployee,
  getCompanyEmployees,
  updateCompanyEmployee,
} from "@/main/services/api.service";
import { useCompanyStore } from "@/main/stores/company.store";
import {
  CreateEmployeeDTO,
  Employee,
  UpdateEmployeeDTO,
} from "@/main/types/employee.type";
import { onMounted, ref } from "vue";
import EmployeeDialog from "../dialogs/employee-dialog.vue";
import DeleteDialog from "../delete-popup.vue";

const companyStore = useCompanyStore();

const dialog = ref(false);
const deleteDialog = ref(false);
const createDialog = ref(false);

const currentEmployee = ref<Employee>({
  email: "",
  name: "",
  username: "",
  id: undefined,
});

const newEmployee = ref<Employee>({
  email: "",
  name: "",
  username: "",
  id: undefined,
});

const employeeId = ref<number>();

onMounted(async () => {
  try {
    if (companyStore.currentCompany?.id) {
      const companyEmployees = await getCompanyEmployees(
        companyStore.currentCompany?.id
      );

      companyStore.employees = companyEmployees;
    }
  } catch (error) {
    console.log(error);
  }
});

const openDialog = (edit: boolean, employee: Employee) => {
  console.log(employee.schedules_attributes);
  currentEmployee.value = employee;

  edit ? (dialog.value = true) : (createDialog.value = true);
};

const openDeleteDialog = (id: number) => {
  deleteDialog.value = true;
  employeeId.value = id;
};

const closeDialog = async () => {
  dialog.value = false;
  createDialog.value = false;
  const emptyEmployee: Employee = {
    email: "",
    name: "",
    username: "",
    id: undefined,
    phone_number: "",
    role: "employee",
    avatar: undefined,
    employee_services_attributes: [],
    schedules_attributes: [],
  };
  currentEmployee.value = emptyEmployee;
  newEmployee.value = emptyEmployee;
  return;
};

const saveEmployee = async (employeeDto: Employee) => {
  if (employeeDto.email) {
    const params: CreateEmployeeDTO = {
      ...(employeeDto as CreateEmployeeDTO),
    };
    try {
      const employee = await createCompanyEmployee(
        companyStore.currentCompany!.id!,
        params
      );

      companyStore.employees.push(employee);
    } catch (error) {
      console.log(error);
    }
  }
  closeDialog();
};

const updateEmployee = async (employeeDto: Employee) => {
  if (companyStore.currentCompany && employeeDto) {
    try {
      const employee = await updateCompanyEmployee(
        companyStore.currentCompany!.id!,
        {
          ...(employeeDto as UpdateEmployeeDTO),
        }
      );

      const index = companyStore.employees.findIndex(
        (ser) => ser.id == employee.id
      );

      if (index != -1) {
        companyStore.employees[index] = {
          ...employee,
        };

        companyStore.$patch({
          employees: companyStore.employees,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  closeDialog();
};

const deleteEmployee = async () => {
  if (companyStore.currentCompany && employeeId.value) {
    try {
      await deleteCompanyEmployee(
        companyStore.currentCompany.id!,
        employeeId.value
      );
      companyStore.employees = companyStore.employees.filter(
        (emp) => emp.id != employeeId.value
      );
    } catch (error) {
      console.log(error);
    }
  }
  deleteDialog.value = false;
  employeeId.value = undefined;
};
</script>

<style lang="scss" scoped>
.table-view {
  h3 {
    margin-bottom: 0;
  }

  &__header {
    grid-template-columns: repeat(3, 1fr);
  }

  &__body {
    grid-template-columns: repeat(3, 1fr);
  }

  &__name {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;
  }
}
</style>
