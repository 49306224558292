<template>
  <v-card class="sign-up__card">
    <v-card-text>
      <div class="sign-up__final-loading" v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>

      <div
        v-else
        class="sign-up__final-content"
        :class="{
          error: error,
        }"
      >
        <template v-if="!error">
          <VIcon icon="mdi-pencil-ruler" size="large" />

          <h3>Готово!</h3>

          <p>
            Тепер залогуйтесь та почніть використовувати нові можливості для
            Вашого бізнесу! Додавайте послуги які надаєте, персонал та багато
            іншого.
          </p>
        </template>
        <template v-else>
          <v-alert
            v-if="!errorMessage.length"
            type="error"
            title="Щось пішло не так"
            text="Спробуйте ще раз або зв'яжіться з службою підтримки"
          ></v-alert>
          <v-alert
            v-else
            type="error"
            title="Помилка"
            :text="errorMessage"
          ></v-alert>
        </template>
      </div>
      <div class="sign-up__btns" v-if="!loading">
        <RouterLink
          :to="Routes.AdminDashboard"
          v-if="!error"
          v-slot="{ navigate }"
        >
          <VBtn @click="navigate" class="sign-up__btn sign-up__btn-black">
            Продовжити
          </VBtn>
        </RouterLink>
        <VBtn v-else class="sign-up__btn" @click="backClick" variant="text">
          Назад
        </VBtn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import { createCompany, signUp } from "@/main/services/api.service";
import { useCompanyStore } from "@/main/stores/company.store";
import { Routes } from "@/main/types/enums/routes.enum";
import { SignUpInfoType } from "@/main/types/signup-info.type";
import axios from "axios";
import { onMounted } from "vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

const { signupInfo } = defineProps<{
  signupInfo: SignUpInfoType;
}>();

const router = useRouter();
const companyStore = useCompanyStore();

const loading = ref(false);
const error = ref(false);
const errorMessage = ref("");

const emit = defineEmits<{
  (e: "backClick"): void;
}>();

const backClick = () => emit("backClick");

onMounted(async () => {
  loading.value = true;
  try {
    const res = await signUp({
      username: signupInfo.login,
      password: signupInfo.password,
      password_confirmation: signupInfo.password_confirmation,
      email: signupInfo.email,
      first_name: signupInfo.user_name?.split(" ")[0],
      last_name: signupInfo.user_name?.split(" ")[0] || "",
      phone_number: signupInfo.phone_number,
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      console.log(err.response);
      if (
        err.response?.data.error &&
        (err.response.data.error == "resource_owner_create_error" ||
          err.response.data.error == "invalid_authentication")
      ) {
        loading.value = false;
        error.value = true;
        errorMessage.value = `Електронна адреса або логін вже зайняті. \n
          Використовуйте іншу або відновіть пароль якщо не пам'ятаєте`;

        return;
      }
    }
  }

  try {
    const company = await createCompany({
      title: signupInfo.company_name,
      location: `${signupInfo.address?.road} ${
        signupInfo.address?.house_number
      }, ${signupInfo.address?.city || signupInfo.address?.town}`,
      geocoords: `${signupInfo.geocoords.lat}, ${signupInfo.geocoords.lon}`,
      schedules_attributes: signupInfo.schedule,
      custom_schedule: signupInfo.custom_schedule || false,
      category_ids: [
        "",
        `${signupInfo.company_category || ""}`,
        `${signupInfo.company_second_category || ""}`,
      ],
    });

    console.log(company);
    router.push(Routes.Admin);
    companyStore.currentCompany = company;
  } catch (error) {
    console.log(error);
  }

  loading.value = false;
});
</script>

<style lang="scss" scoped>
.sign-up {
  &__card {
    width: 100%;
    height: 642px;
  }

  .v-card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__final-loading {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__final-content {
    width: 100%;
    text-align: center;
    padding: 9px 90px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.error {
      padding: 9px 30px;
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
      max-width: 300px;
      margin-bottom: 24px;
    }
    .v-icon {
      color: #2e2a2a;
      font-size: 60px;
      margin-bottom: 75px;
    }

    input {
      display: block;
      width: 100%;
      border-radius: 15px;
      background-color: #ececec;
      padding: 15px;
      text-align: center;
      outline-color: #c4c3c3;
    }
    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
    }
  }
}
</style>
