<template>
  <VDialog v-model="deleteDialog" width="500">
    <VCard>
      <VCardTitle class="headline grey lighten-2" primary-title>
        Підтвердження видалення
      </VCardTitle>

      <VCardText> Ви упевнені? </VCardText>

      <VDivider></VDivider>

      <VCardActions>
        <VBtn color="primary" @click="cancel"> Відмінити </VBtn>
        <v-spacer></v-spacer>
        <VBtn color="primary" @click="callDelete"> Підтвердити </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
<script lang="ts" setup>
const deleteDialog = defineModel<boolean>();

const emit = defineEmits(["delete", "cancel"]);

const cancel = () => {
  deleteDialog.value = false;
  emit("cancel");
};

const callDelete = () => {
  deleteDialog.value = false;
  emit("delete");
};
</script>
