<template>
  <v-card class="sign-up__card">
    <v-card-text>
      <div class="sign-up__category">
        <VIcon icon="mdi-store-outline" size="large" class="sign-up__icon" />

        <h3>Виберіть категорію Вашого бізнесу</h3>
        <div class="sign-up__group">
          <select v-model="companyCategory" required ref="companyCategoryInput">
            <option value disabled selected>Обрати основну категорію</option>
            <option
              :value="`${category.id}`"
              v-for="category in filteredCategories"
              :key="category.id"
            >
              {{ category.title }}
            </option>
          </select>

          <select
            v-model="compantSecondCategory"
            ref="compantSecondCategoryInput"
          >
            <option value selected>Обрати додаткову категорію</option>
            <!-- <option value="1">Test category</option> -->

            <option
              :value="`${category.id}`"
              v-for="category in filteredSecondCategories"
              :key="category.id"
            >
              {{ category.title }}
            </option>
          </select>
        </div>

        <p>
          Основна категорія це та, яка найбільше відповідає Вашому роду
          діяльності, та саме в цій категорії клієнти зможуть знайти Ваш бізнес.
        </p>
      </div>
      <div class="sign-up__btns">
        <VBtn @click="nextClick" class="sign-up__btn sign-up__btn-black">
          Продовжити
        </VBtn>
        <VBtn class="sign-up__btn" @click="backClick" variant="text">
          Назад
        </VBtn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import { getCategories } from "@/main/services/api.service";
import { Category } from "@/main/types/category.type";
import { computed } from "vue";
import { onMounted } from "vue";
import { ref } from "vue";

const emit = defineEmits<{
  (
    e: "nextClick",
    value: {
      company_category: number;
      company_second_category: number;
    }
  ): void;
  (e: "backClick"): void;
}>();

onMounted(() => {
  try {
    loadCategories();
  } catch (error) {
    console.log(error);
  }
});

const loading = ref(false);
const categories = ref<Category[]>([]);
const companyCategory = ref("");
const compantSecondCategory = ref("");
const companyCategoryInput = ref<HTMLSelectElement>();
const compantSecondCategoryInput = ref<HTMLSelectElement>();

const filteredCategories = computed(() =>
  categories.value.filter(
    (category) => category.id != parseInt(compantSecondCategory.value)
  )
);

const filteredSecondCategories = computed(() =>
  categories.value.filter(
    (category) => category.id != parseInt(companyCategory.value)
  )
);

const loadCategories = async () => {
  loading.value = true;

  try {
    const res: Category[] = await getCategories();
    categories.value = res;
  } catch (error) {
    console.log(error);
  }

  loading.value = false;
};

const nextClick = () => {
  if (!companyCategoryInput.value?.checkValidity()) {
    companyCategoryInput.value?.reportValidity();
    companyCategoryInput.value?.focus();
    return;
  }

  if (!compantSecondCategoryInput.value?.checkValidity()) {
    compantSecondCategoryInput.value?.reportValidity();
    compantSecondCategoryInput.value?.focus();
    return;
  }

  emit("nextClick", {
    company_category: parseInt(companyCategory.value!),
    company_second_category: parseInt(compantSecondCategory.value!),
  });
};
const backClick = () => emit("backClick");
</script>

<style lang="scss" scoped>
.sign-up {
  &__card {
    width: 100%;
    height: 642px;
  }

  &__icon {
    color: #2e2a2a;
    font-size: 60px;
    margin-bottom: 75px;
  }

  .v-card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__category {
    width: 100%;
    text-align: center;
    padding: 9px 90px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      color: #2e2a2a;
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      padding-top: 24px;
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
      max-width: 300px;
      margin-bottom: 24px;
    }

    select {
      cursor: pointer;
      border-radius: 15px;
      background: #ececec;
      padding: 15px;
      outline-color: #c4c3c3;
      color: #807575;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
  }
}
</style>
