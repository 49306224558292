<template>
  <v-card class="sign-up__card">
    <v-card-text>
      <div class="sign-up__confirm-address-content">
        <VIcon
          icon="mdi-navigation-variant-outline"
          size="large"
          class="sign-up__icon"
        />

        <h3>Підтвердьте свою адресу</h3>
        <div class="sign-up__group" v-if="address">
          <input type="text" v-model="street" placeholder="Вулиця" />

          <input
            type="text"
            v-model="buildNumber"
            placeholder="Номер будівлі"
          />

          <input type="text" v-model="city" placeholder="Місто" readonly />

          <input
            type="text"
            v-model="postcode"
            readonly
            placeholder="Поштовий індекс"
            class="small"
          />
        </div>
      </div>
      <div class="sign-up__btns">
        <VBtn @click="nextClick" class="sign-up__btn sign-up__btn-black">
          Продовжити
        </VBtn>
        <VBtn class="sign-up__btn" @click="backClick" variant="text">
          Назад
        </VBtn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import { INominatimAddress } from "@/main/services/nominatim.service";
import { computed } from "vue";
import { ref } from "vue";
type Props = {
  address?: INominatimAddress;
  geocoords: {
    lat: string;
    lon: string;
  };
};

const props = withDefaults(defineProps<Props>(), {
  address: undefined,
});

const emit = defineEmits<{
  (e: "nextClick"): void;
  (e: "backClick"): void;
  (e: "update:street", value: string): void;
  (e: "update:buildNumber", value: string): void;
  (e: "update:city", value: string): void;
  (e: "update:postcode", value: string): void;
}>();

const street = computed({
  get: (): string => (props.address ? props.address.road || "" : ""),
  set: (value: string): void => emit("update:street", value),
});
const buildNumber = computed({
  get: (): string => (props.address ? props.address.house_number || "" : ""),
  set: (v) => emit("update:buildNumber", v),
});
const city = computed({
  get: () => (props.address ? props.address.city || props.address.town : ""),
  set: (v) => emit("update:city", v),
});
const postcode = computed({
  get: () => (props.address ? props.address.postcode || "" : ""),
  set: (v) => emit("update:postcode", v),
});

const passwordNameInput = ref<HTMLInputElement>();
const passwordConfirmationInput = ref<HTMLInputElement>();

const nextClick = () => emit("nextClick");
const backClick = () => emit("backClick");
</script>

<style lang="scss" scoped>
.sign-up {
  &__card {
    width: 100%;
    height: 642px;
  }

  &__icon {
    color: #2e2a2a;
    font-size: 60px;
    margin-bottom: 40px;
  }

  .v-card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__confirm-address-content {
    width: 100%;
    text-align: center;
    padding: 9px 90px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
      max-width: 300px;
      margin-bottom: 24px;
    }

    input {
      display: block;
      width: 100%;
      border-radius: 15px;
      background-color: #ececec;
      padding: 15px 40px 15px 15px;
      text-align: center;
      outline-color: #c4c3c3;
      color: #807575;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
    }
  }
}
</style>
