export enum Routes {
  Main = "/",
  Login = "/login",
  SignUp = "/signup",
  Admin = "/ad/",
  AdminDashboard = "/dashboard",
  AdminSettings = "/settings",
  AdminEmployees = "/employees",
  AdminSchedule = "/schedule",
}
