<template>
  <VDialog
    :model-value="opened"
    @update:model-value="closeDialog"
    width="1000"
    eager
  >
    <VForm validate-on="submit lazy" @submit.prevent="closeDialog(false)">
      <VCard>
        <VCardTitle>
          <span class="text-h5">{{
            currentEmployee.id
              ? "Змінити данні працівника"
              : "Додати працівника"
          }}</span>
        </VCardTitle>
        <VCardText v-if="currentEmployee">
          <VContainer>
            <VRow>
              <VCol cols="4">
                <EmployeeForm
                  v-model:name="currentEmployee.name"
                  v-model:username="currentEmployee.username"
                  v-model:email="currentEmployee.email"
                  v-model:avatar="currentEmployee.avatar"
                  v-model:mobile="currentEmployee.phone_number"
                  v-model:role="currentEmployee.role"
                  :editable="editable"
                />
              </VCol>
              <VCol cols="8">
                <VTabs v-model="tab">
                  <VTab value="one">Послуги</VTab>
                  <VTab value="two">Робочі години</VTab>
                </VTabs>
                <VWindow v-model="tab">
                  <VWindowItem value="one">
                    <VContainer>
                      <VRow>
                        <VRow align="center" justify="space-between">
                          <VCol cols="9">
                            <VSelect
                              v-model="service_id"
                              :items="serviceItems"
                              item-title="name"
                              item-value="id"
                              label="Послуги"
                            >
                              <template #no-data>
                                <p>Послуг не знайдено</p>
                              </template>
                            </VSelect>
                          </VCol>
                          <VCol cols="3">
                            <VBtn @click="addEmployeeService"> Додати </VBtn>
                          </VCol>
                        </VRow>
                        <VCol cols="12">
                          <VTable>
                            <thead>
                              <tr>
                                <th>Назва</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="employee_services_attribute in employeeServices"
                                :key="employee_services_attribute?.id"
                              >
                                <td>{{ employee_services_attribute?.name }}</td>
                                <td>
                                  <VBtn
                                    icon
                                    @click="
                                      removeService(employee_services_attribute)
                                    "
                                  >
                                    <VIcon>mdi-delete</VIcon>
                                  </VBtn>
                                </td>
                              </tr>
                            </tbody>
                          </VTable>
                        </VCol>
                      </VRow>
                    </VContainer>
                  </VWindowItem>
                  <VWindowItem value="two">
                    <ScheduleList
                      @update:weekobject="onWeekObjectUpdate"
                      :schedules="currentEmployee.schedules_attributes"
                      is-employee
                      v-if="currentEmployee.schedules_attributes"
                    />
                  </VWindowItem>
                </VWindow>
              </VCol>
            </VRow>
          </VContainer>
        </VCardText>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="closeDialog(true)"
          >
            Відміна
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" type="submit">
            Зберегти
          </v-btn>
        </v-card-actions>
      </VCard>
    </VForm>
  </VDialog>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { Employee } from "../../types/employee.type";
import EmployeeForm from "../employee-form.vue";
import ScheduleList from "../schedule-list.vue";
import { WorkingHoursSchedule } from "@/main/types/working-hours-schedule.type";
import { useGlobalStore } from "@/main/stores/global.store";

const globalStore = useGlobalStore();

const props = withDefaults(
  defineProps<{
    employee: Employee;
    opened: boolean;
    editable: boolean;
  }>(),
  { editable: false }
);

const currentEmployee = ref<Employee>({
  ...props.employee,
});

const tab = ref("one");
const service_id = ref<number | null>(null);

const employeeServices = computed(() => {
  return currentEmployee.value.employee_services_attributes
    ?.map((employee_services_attribute) => {
      const foundService = globalStore.services.find(
        (s) => s.id === employee_services_attribute.service_id
      );
      return {
        name: foundService?.name,
        id: employee_services_attribute.id,
        _destroy: employee_services_attribute._destroy,
      };
    })
    .filter((s) => s._destroy != 1);
});

const serviceItems = computed(() => {
  return globalStore.services.filter(
    (service) =>
      !currentEmployee.value.employee_services_attributes?.find(
        (s) => s.service_id === service.id
      )
  );
});

watch(props, (newValue, oldValue) => {
  if (newValue.opened) {
    currentEmployee.value = {
      ...currentEmployee.value,
      ...props.employee,
    } as Employee;
  }
  if (!newValue.opened) {
    tab.value = "one";
  }
});

const addEmployeeService = () => {
  if (service_id.value) {
    currentEmployee.value.employee_services_attributes = [
      ...(currentEmployee.value.employee_services_attributes || []),
      {
        service_id: service_id.value,
      },
    ];
    service_id.value = null;
  }
};

const removeService = (employee_services_attribute: any) => {
  currentEmployee.value.employee_services_attributes =
    currentEmployee.value.employee_services_attributes?.map((s) => {
      if (s.id === employee_services_attribute.id) {
        s._destroy = 1;
      }
      return s;
    });
};

const onWeekObjectUpdate = (workingHours: WorkingHoursSchedule[]) => {
  currentEmployee.value.schedules_attributes = workingHours;
};

const emit = defineEmits<{
  (e: "saveEmployee", value: Employee): void;
  (e: "closeWithoutSave"): void;
}>();

const closeDialog = (cancel: boolean = true) => {
  if (cancel) {
    emit("closeWithoutSave");
  } else {
    emit("saveEmployee", currentEmployee.value);
  }
  currentEmployee.value = {} as Employee;
};
</script>

<style lang="scss" scoped></style>
