<template>
  <div class="sign-in">
    <v-card class="sign-in__card">
      <v-card-text>
        <div class="sign-in__content">
          <VIcon icon="mdi-account-key" size="large" class="sign-in__icon" />

          <div class="sign-in__group">
            <input
              type="text"
              placeholder="Введіть логін або пошту"
              v-model.trim="login"
              ref="loginInput"
              required
            />

            <div class="sign-in__password-wrapper">
              <input
                required
                ref="passwordInput"
                :type="showPassword ? 'text' : 'password'"
                minlength="8"
                v-model="password"
                placeholder="Введіть пароль"
              />

              <VIcon
                v-if="showPassword"
                icon="mdi-eye"
                size="large"
                class="show-password"
                @click="showPassword = !showPassword"
              />

              <VIcon
                v-if="!showPassword"
                icon="mdi-eye-off"
                size="large"
                class="show-password"
                @click="showPassword = !showPassword"
              />
            </div>
          </div>
        </div>
        <div class="sign-up__btns">
          <VBtn
            @click="nextClick"
            :loading="loading"
            class="sign-up__btn sign-up__btn-black"
          >
            Увійти
          </VBtn>
          <RouterLink :to="Routes.Main" v-slot="{ navigate }">
            <VBtn class="sign-up__btn" @click="navigate" variant="text">
              Назад
            </VBtn>
          </RouterLink>
        </div>
      </v-card-text>
    </v-card>
    <VSnackbar
      v-model="snackbar"
      :text="snackbarText"
      color="error"
      timeout="4000"
    >
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Закрити
        </v-btn>
      </template>
    </VSnackbar>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { getUserCompanies, logout, signIn } from "../services/api.service";
import { useRouter } from "vue-router";
import { Routes } from "../types/enums/routes.enum";
import axios, { AxiosError } from "axios";
import { useCompanyStore } from "../stores/company.store";

const login = ref("");
const password = ref("");

const router = useRouter();
const companyStore = useCompanyStore();

const showPassword = ref(false);
const loading = ref(false);
const snackbar = ref(false);
const snackbarText = ref("");

const passwordInput = ref<HTMLInputElement>();
const loginInput = ref<HTMLInputElement>();

const nextClick = async () => {
  passwordInput.value?.setCustomValidity("");
  loginInput.value?.setCustomValidity("");
  passwordInput.value?.reportValidity();
  loginInput.value?.reportValidity();

  if (!loginInput.value?.checkValidity()) {
    loginInput.value?.reportValidity();
    loginInput.value?.focus();

    return;
  }

  if (!passwordInput.value?.checkValidity()) {
    passwordInput.value?.reportValidity();
    passwordInput.value?.focus();
    return;
  }

  try {
    loading.value = true;
    const isLoggined = await signIn(login.value, password.value);
    if (isLoggined) {
      const companies = await getUserCompanies();

      if (companies.length) {
        companyStore.userCompanies = companies;
        companyStore.currentCompany = companies[0];
        router.push(Routes.Admin);
      } else {
        await logout();
      }
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response?.data) {
        if (
          error.response.data.error == "invalid_authentication" ||
          error.response.data.error == "invalid_email"
        ) {
          snackbarText.value = "Помилка при вході. Перевірте логін або пароль";
          snackbar.value = true;
        }

        if (error.response.data.error == "NO_COMPANIES") {
          snackbarText.value =
            "У Вас ще не має компанії. Зареєструйте через сторінку реєстрації";
          snackbar.value = true;
          await logout();
        }
      }
    } else if (error instanceof Error) {
      console.log(error);
      snackbarText.value =
        "Сталася помилка. БУдь ласка зверніться у службу підтримки";
      snackbar.value = true;
    } else {
      console.log(error);
    }
  }

  loading.value = false;
};
</script>

<style lang="scss" scoped>
.sign-in {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  padding: 0 20px;

  &__card {
    width: 100%;
    height: 642px;
    max-width: 564px;
    width: 100%;
    border-radius: 15px;
  }

  &__group {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__password-wrapper {
    position: relative;

    i {
      position: absolute;
      right: 10px;
      top: calc(50% - 12px);
    }
  }

  &__icon {
    color: #2e2a2a;
    font-size: 70px;
    margin-bottom: 110px;
  }

  .v-card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__content {
    width: 100%;
    text-align: center;
    padding: 60px 90px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
      max-width: 300px;
      margin-bottom: 24px;
    }

    input {
      display: block;
      width: 100%;
      border-radius: 15px;
      background-color: #ececec;
      padding: 15px 40px 15px 15px;
      text-align: center;
      outline-color: #c4c3c3;
      color: #807575;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
    }
  }
}
</style>
