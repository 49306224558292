import { AuthTokens } from "../types/auth-tokens.type";
import { LocalStorageKeys, localForageStorage } from "./storage.helper";

export const shouldRefreshToken = (refreshedAt: number, expiresIn: number) => {
  const currentTime = new Date().getTime();
  return refreshedAt + expiresIn * 1000 < currentTime;
};

export const isLoggined = async (): Promise<boolean> => {
  const tokens = await localForageStorage.getItem<AuthTokens>(
    LocalStorageKeys.Tokens
  );

  if (tokens) {
    return true;
  }

  return false;
};
