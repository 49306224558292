import localforage from "localforage";

export enum LocalStorageKeys {
  Tokens = "tokens",
}

export const localForageStorage = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: "booqimate",
  version: 1,
  storeName: "booqimate",
});
