<template>
  <v-card class="sign-up__card">
    <v-card-text>
      <div class="sign-up__password-content">
        <VIcon
          icon="mdi-lock-open-outline"
          size="large"
          class="sign-up__icon"
        />

        <h3>Налаштування пароля</h3>
        <div class="sign-up__group">
          <div class="sign-up__password-wrapper">
            <input
              required
              ref="passwordNameInput"
              :type="showPassword ? 'text' : 'password'"
              minlength="8"
              v-model="password"
              placeholder="Введіть пароль для аккаунту компанії"
            />

            <VIcon
              v-if="showPassword"
              icon="mdi-eye"
              size="large"
              class="show-password"
              @click="showPassword = !showPassword"
            />

            <VIcon
              v-if="!showPassword"
              icon="mdi-eye-off"
              size="large"
              class="show-password"
              @click="showPassword = !showPassword"
            />
          </div>

          <div class="sign-up__password-wrapper">
            <input
              required
              ref="passwordConfirmationInput"
              :type="showConfirmationPassword ? 'text' : 'password'"
              minlength="8"
              v-model="passwordConfirmation"
              placeholder="Введіть пароль повторно"
            />

            <VIcon
              v-if="showConfirmationPassword"
              icon="mdi-eye"
              size="large"
              class="show-password"
              @click="showConfirmationPassword = !showConfirmationPassword"
            />

            <VIcon
              v-if="!showConfirmationPassword"
              icon="mdi-eye-off"
              size="large"
              class="show-password"
              @click="showConfirmationPassword = !showConfirmationPassword"
            />
          </div>
        </div>
      </div>
      <div class="sign-up__btns">
        <VBtn @click="nextClick" class="sign-up__btn sign-up__btn-black">
          Продовжити
        </VBtn>
        <VBtn class="sign-up__btn" @click="backClick" variant="text">
          Назад
        </VBtn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const emit = defineEmits<{
  (
    e: "nextClick",
    value: {
      password: string;
      password_confirmation: string;
    }
  ): void;
  (e: "backClick"): void;
}>();

const password = ref("");
const passwordConfirmation = ref("");

const showPassword = ref(false);
const showConfirmationPassword = ref(false);

const passwordNameInput = ref<HTMLInputElement>();
const passwordConfirmationInput = ref<HTMLInputElement>();

const nextClick = () => {
  if (password.value != passwordConfirmation.value) {
    passwordConfirmationInput.value?.setCustomValidity("Паролі не збігаються.");
    passwordConfirmationInput.value?.reportValidity();
    passwordConfirmationInput.value?.focus();
    return;
  } else {
    passwordConfirmationInput.value?.setCustomValidity("");
    passwordConfirmationInput.value?.reportValidity();
  }

  if (!passwordNameInput.value?.checkValidity()) {
    passwordNameInput.value?.reportValidity();
    passwordNameInput.value?.focus();
    return;
  }

  if (!passwordConfirmationInput.value?.checkValidity()) {
    passwordConfirmationInput.value?.reportValidity();
    passwordConfirmationInput.value?.focus();
    return;
  }

  emit("nextClick", {
    password: password.value,
    password_confirmation: passwordConfirmation.value,
  });
};
const backClick = () => emit("backClick");
</script>

<style lang="scss" scoped>
.sign-up {
  &__card {
    width: 100%;
    height: 642px;
  }

  &__password-wrapper {
    position: relative;

    i {
      position: absolute;
      right: 10px;
      top: calc(50% - 12px);
    }
  }

  &__icon {
    color: #2e2a2a;
    font-size: 60px;
    margin-bottom: 75px;
  }

  .v-card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__password-content {
    width: 100%;
    text-align: center;
    padding: 9px 90px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #2e2a2a;
      max-width: 300px;
      margin-bottom: 24px;
    }

    input {
      display: block;
      width: 100%;
      border-radius: 15px;
      background-color: #ececec;
      padding: 15px 40px 15px 15px;
      text-align: center;
      outline-color: #c4c3c3;
      color: #807575;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
    }
  }
}
</style>
