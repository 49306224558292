<template>
  <VRow>
    <VCol cols="12">
      <VRow justify="center" class="employee-form__avatar">
        <input
          ref="avatarInput"
          type="file"
          name="avatar"
          style="display: none"
          @change="onFileChange"
        />
        <VBtn
          v-if="!avatar"
          icon="mdi-account-outline"
          variant="plain"
          @click="selectFile"
        />
        <VAvatar v-if="avatar" color="grey" @click="selectFile" class="pointer">
          <VImg :src="avatarUrl" alt="Avatar" v-if="avatar" />
          <VIcon icon="mdi-account" v-else></VIcon>
        </VAvatar>
      </VRow>
    </VCol>
    <VCol cols="12">
      <VTextField
        label="Ім'я*"
        variant="outlined"
        name="name"
        :rules="[rules.required]"
        :model-value="name"
        @update:model-value="emit('update:name', $event)"
      />
      <VTextField
        label="Номер телефону*"
        variant="outlined"
        :disabled="!editable"
        :rules="[rules.required]"
        :model-value="mobile"
        name="phone_number"
        @update:model-value="emit('update:mobile', $event)"
      />
      <VTextField label="Позиція" variant="underlined" required />
      <VTextField
        label="Електронна пошта*"
        variant="outlined"
        type="email"
        :disabled="!editable"
        :rules="[rules.required, rules.email]"
        :model-value="email"
        @update:model-value="emit('update:email', $event)"
      />
      <VTextField
        label="Логін*"
        variant="outlined"
        :disabled="!editable"
        :rules="[rules.required]"
        :model-value="username"
        name="username"
        @update:model-value="emit('update:username', $event)"
      />
      <VSelect
        :items="[
          { text: 'Адміністратор', value: 'company_admin' },
          { text: 'Співвласник', value: 'owner' },
          { text: 'Працівник', value: 'employee' },
        ]"
        label="Рівень дозволу"
        item-title="text"
        item-value="value"
        :model-value="role"
        @update:model-value="emit('update:role', $event)"
      />
    </VCol>
  </VRow>
</template>
<script lang="ts" setup>
import { computed, ref, VNodeRef } from "vue";

const props = withDefaults(
  defineProps<{
    name: string;
    mobile: string;
    email: string;
    username: string;
    role: string;
    avatar: string | File;
    editable?: boolean;
  }>(),
  {
    name: "",
    mobile: "",
    email: "",
    username: "",
    role: "employee",
    avatar: "",
    editable: true,
  }
);

const avatarInput = ref<VNodeRef | null>(null);

const rules = {
  required: (value: string) => !!value || "Обов'язкове поле.",
  email: (value: string) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Некоректний e-mail.";
  },
};

const avatarUrl = computed(() => {
  console.log(props.avatar);
  if (props.avatar) {
    return isFile(props.avatar)
      ? URL.createObjectURL(props.avatar as File)
      : (props.avatar as string);
  }
  return "";
});

const emit = defineEmits<{
  (e: "update:name", value: string): void;
  (e: "update:mobile", value: string): void;
  (e: "update:email", value: string): void;
  (e: "update:username", value: string): void;
  (e: "update:role", value: string): void;
  (e: "update:avatar", value: string | File): void;
}>();

const isFile = (variable: any): boolean => variable instanceof File;

const selectFile = () => {
  if (avatarInput.value) {
    avatarInput.value.click();
  }
};
const onFileChange = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const file = target.files?.[0];
  console.log(file);
  if (file) {
    emit("update:avatar", file);
  }
};
</script>

<style lang="scss" scoped>
.employee-form {
  &__avatar {
    .v-btn {
      width: 80px;
      height: 80px;
      font-size: 3rem;
    }

    .v-avatar {
      width: 80px;
      height: 80px;
    }
  }
}

.pointer {
  cursor: pointer;
}
</style>
