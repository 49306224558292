<template>
  <div class="photo-uploader" v-bind="$attrs">
    <label
      class="photo-uploader__label"
      :class="{
        'photo-uploader__label-enter': entering,
        'photo-uploader__with-img': file,
      }"
      @dragenter.prevent="entering = true"
      @dragleave.prevent="entering = false"
      @drop.prevent="handleDrop"
    >
      <input type="file" @input="handleInput" accept=".jpg, .png" />

      <span class="photo-uploader__placeholder" v-if="!file && !image">
        <img :src="PhotoPlaceholder" alt="" />
      </span>

      <span class="photo-uploader__preview" v-if="file">
        <span
          class="photo-uploader__preview-img"
          :style="{
            backgroundImage: `url(${prepareSrc(file)})`,
          }"
        ></span>
      </span>

      <span class="photo-uploader__preview" v-if="image && !file">
        <span
          class="photo-uploader__preview-img"
          :style="{
            backgroundImage: `url(${image!.url})`,
          }"
        ></span>
      </span>
    </label>
  </div>
</template>

<script lang="ts" setup>
import PhotoPlaceholder from "@/main/assets/img/upload.svg";
import { onUnmounted, watch } from "vue";
import { onMounted } from "vue";
import { ref } from "vue";

const events = ["dragenter", "dragleave", "dragover", "drag"];
const allowedExtensions = ["jpg", "png", "jpeg"];

onMounted(() => {
  events.forEach((event) =>
    document.body.addEventListener(event, (e) => e.preventDefault())
  );
});

onUnmounted(() => {
  events.forEach((event) =>
    document.body.removeEventListener(event, (e) => e.preventDefault())
  );
});

const file = ref<File>();

const props = defineProps<{
  image?: { id: number; url: string };
}>();

watch(
  props,
  (newValue, oldValue) => {
    if (newValue.image?.id) {
      file.value = undefined;
    }
  },
  {
    deep: true,
  }
);
const emit = defineEmits<{
  (
    e: "updateImages",
    value: {
      id?: number;
      url: File;
    }
  ): void;
}>();

const entering = ref(false);

const handleDrop = (e: DragEvent) => {
  entering.value = false;
  let fileList = e.dataTransfer?.files as FileList;
  const filteredFiles = [...fileList].filter((value) =>
    allowedExtensions.includes(value.name.split(".").pop()!)
  );
  if (filteredFiles.length) {
    file.value = filteredFiles[0];

    emit("updateImages", {
      id: props.image?.id,
      url: file.value,
    });
  }
};

const handleInput = (e: Event) => {
  const fileList = (e.target as HTMLInputElement).files as FileList;
  const filteredFiles = [...fileList].filter((value) =>
    allowedExtensions.includes(value.name.split(".").pop()!)
  );
  if (filteredFiles.length) {
    file.value = filteredFiles[0];

    emit("updateImages", {
      id: props.image?.id,
      url: file.value,
    });
  }
};

const prepareSrc = (file: File) => URL.createObjectURL(file);
</script>

<style lang="scss" scoped>
.photo-uploader {
  &__label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 10px;
    background: #e7eaee;

    &-enter {
      background: #b3b6b9;
    }
  }

  input {
    visibility: hidden;
    display: none;
  }

  &__placeholder {
  }

  &__with-img {
    background: transparent;
  }

  &__preview {
    display: block;
    width: 100%;
    height: 100%;

    &-img {
      display: block;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      border-radius: 10px;
    }
  }
}
</style>
