import { defineStore } from "pinia";
import { Service } from "../types/service.type";
import { Employee } from "../types/employee.type";

interface GlobalStore {
  services: Service[];
  employees: Employee[];
}

export const useGlobalStore = defineStore("globalStore", {
  state: (): GlobalStore => ({
    services: [],
    employees: [],
  }),
  persist: {
    paths: ["services", "employees"],
  },
});
